import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL;

class ProfileMappingService {

  async get() {
    const config = {
      headers: authHeader()
    };

    const response = await axios
      .get(`${API_URL}profile-mapping/list`, config);

    return response.data;
  }

  async bulkMappingProfile(mappedProfiles) {
    const config = {
      headers: authHeader()
    };

    const response = await axios
      .post(`${API_URL}profile-mapping/bulk-mapping-profile`, {
        mappedProfiles
      }, config);
    return response.data;
  }

  async mappingProfile(bdTeamId, uprosterProfileId) {
    const config = {
      headers: authHeader()
    };

    const response = await axios
      .post(`${API_URL}profile-mapping/mapping-profile`, {
        bdTeamId,
        uprosterProfileId
      }, config);
    return response.data;
  }

  async unMappingProfile(id) {
    const config = {
      headers: authHeader()
    };

    const response = await axios
      .post(`${API_URL}profile-mapping/unmapping-profile`, {
        id
      }, config);
    return response.data;
  }

  async searchUnmappedProfiles(search,unsavedProfiles) {
    const config = {
      headers: authHeader()
    };

    const response = await axios
      .post(`${API_URL}profile-mapping/searchUnmappedProfiles`, {
        search,unsavedProfiles
      }, config);
    return response.data;
  }
}

export default new ProfileMappingService();