<template>
  <div class="profile-data-wrapper" v-show="!loading">
    <template
      v-if="
        userPermissions &&
        userPermissions.profile_ownership &&
        userPermissions.profile_ownership.length > 0 &&
        userPermissions.profile_ownership.includes('View')
      "
    >
      <div class="profile-options">
        <div class="row align-items-center">
          <div class="col">
            <h2 class="section-heading">MAPPED PROFILES</h2>
          </div>
          <div class="col flex-grow-1 d-flex justify-content-end">
            <div class="search-container">
              <input
                type="text"
                placeholder="Search.."
                name="search"
                @keyup="performMappedProfilesSearch"
                v-model.trim="searchMappedProfilequery"
                class="form-control--filled"
              />
              <button type="submit">
                <span class="lnr lnr-magnifier"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="table-sm-spacing custom-table no-arrows mapped-table">
        <table class="table">
          <thead>
            <tr>
              <th>BD Team</th>
              <th>Role</th>
              <th>Total Profiles Mapped</th>
              <th>Mapped Profiles</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="profile in mappedProfiles"
              :key="`mapped-profiles-${profile.Id}`" :id="`${profile.Id}`"
            >
              <td>{{ profile.Name }}</td>
              <td>{{ profile.Role }}</td>
              <td>{{ profile.mapped_profiles_count }}</td>
              <td>
                <div
                  :class="`dragable-column mapped-profile-list mapped-list-${profile.Id}`"
                >
                  <draggable
                    tag="ul"
                    class="listing"
                    :forceFallback="true"
                    :list="profile.mapped_profile_with_uproster"
                    item-key="name"
                    group="my-group"
                    :sort="false"
                    @start="mappedDragStart"
                    @end="mappedDragEnd"
                    ghost-class="selected-mapped-profile"
                    :disabled="
                      !userPermissions.profile_ownership.includes('Update')
                    "
                  >
                    <template #item="{ element, index }">
                      <li :key="index" :item="element.Id" :id="element.Id">
                        <div class="tbl-badges" :data-profileid="( element.ProfileId ) ? element.ProfileId : element.Id" :data-is-dragged="( element.ProfileId ) ? 'false' : 'true'">
                          <template v-if="element.ProfileName" 
                            > {{ element.ProfileName }}
                            <span
                              class="unmap-profile-remove badge-cls"
                              v-on:click.prevent="unMappingProfile(element)"
                              v-tippy="`Remove Profile`"
                              v-if="
                                userPermissions.profile_ownership.includes(
                                  'Update'
                                )
                              "
                              >×</span
                            ></template
                          >
                        </div>
                      </li>
                    </template>
                  </draggable>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="profile-footer d-flex justify-content-end">
          <input
            type="hidden"
            name="unsavedChanges"
            v-model="unsavedChanges"
          />
          <button
            @click.prevent="refreshMapping();"
            class="btn primary-btn"
            >SAVE</button>
        </div>
      </div>
      <div
        class="profile-options"
        v-if="unmappedProfiles.length || searchUnmappedProfilequery != null"
      >
        <div class="row align-items-center">
          <div class="col">
            <h2 class="section-heading">UNMAPPED PROFILES</h2>
          </div>
          <div class="col flex-grow-1 d-flex justify-content-end">
            <div class="search-container">
              <input
                type="text"
                placeholder="Search.."
                name="search"
                @keyup="performUnmappedProfilesSearch"
                v-model.trim="searchUnmappedProfilequery"
                class="form-control--filled"
              />
              <button type="submit">
                <span class="lnr lnr-magnifier"></span>
              </button>
            </div>
          </div>
        </div>
        <div class="table-sm-spacing custom-table no-arrows">
          <div
            class="unmapped-profile-list custom-scroll"
            v-if="unmappedProfiles.length"
          >
            <draggable
              tag="ul"
              class="listing"
              :forceFallback="true"
              :list="unmappedProfiles"
              item-key="name"
              group="my-group"
              :sort="false"
              @start="mappedDragStart"
              @end="mappedDragEnd"
              ghost-class="selected-unmapped-profile"
              :disabled="!userPermissions.profile_ownership.includes('Update')"
            >
              <template #item="{ element }">
                <li :key="element.ProfileName" :item="element.Id" >
                  <div class="tbl-badges" :data-profileid="( element.ProfileId ) ? element.ProfileId : element.Id">
                    {{ element.ProfileName }}
                  </div>
                </li>
              </template>
            </draggable>
          </div>
          <div v-else>
            No records found
            <template v-if="searchUnmappedProfilequery"
              >for "{{ searchUnmappedProfilequery }}"</template
            >
          </div>
        </div>
      </div>
    </template>
    <template
      v-if="
        userPermissions &&
        userPermissions.profile_ownership &&
        (userPermissions.profile_ownership.length === 0 ||
          (userPermissions.profile_ownership.length > 0 &&
            !userPermissions.profile_ownership.includes('View')))
      "
      >Permission Denied.</template
    >
  </div>
</template>
<script>
import draggable from "vuedraggable";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.html5.js";
import $ from "jquery";
import common from "../mixins/common";
import profileMappingService from "../services/profileMapping.service";

export default {
  components: {
    draggable,
  },
  data() {
    return {
      loading: true,
      unsavedChanges: false,
      mappedProfiles: [],
      unmappedProfiles: [],
      searchMappedProfilequery: null,
      searchUnmappedProfilequery: null,
    };
  },
  mixins: [common],
  async mounted() {
    await this.getProfiles();
    this.loading = false;
  },
  methods: {
    async getProfiles() {
      let vm = this;
      const userPermissions = await vm.userPermissions;
      if (
        userPermissions &&
        userPermissions.profile_ownership &&
        userPermissions.profile_ownership.length > 0
      ) {
        try {
          vm.$store.commit("loadingStatus", true);
          const response = await profileMappingService.get();
          vm.mappedProfiles = response.mappedProfiles;
          vm.unmappedProfiles = response.unmappedProfiles;

          var dT = $(".table").DataTable();
          dT.destroy();

          vm.$nextTick(function () {
            $(".table").DataTable({
              iDisplayLength: 10,
              bLengthChange: false,
              ordering: false,
              columnDefs: [
                { targets: [2], searchable: false },
                /* { "width": "50%", "targets": 3 } */
              ],
              info: false,
              fnDrawCallback: function (oSettings) {
                if (oSettings._iDisplayLength >= oSettings.fnRecordsDisplay()) {
                  $(oSettings.nTableWrapper)
                    .find(".dataTables_paginate")
                    .hide();
                } else {
                  $(oSettings.nTableWrapper)
                    .find(".dataTables_paginate")
                    .show();
                }
              },
            });
            $(".dt-buttons, .dataTables_filter").hide();
          });

          vm.$store.commit("loadingStatus", false);
        } catch (error) {
          const message = vm.errorMessage(error);
          vm.toastMessage(message, "error");
          vm.$store.commit("loadingStatus", false);
        }
      }
    },
    async refreshMapping(){
      let vm = this;
      vm.$store.commit("loadingStatus", false);

      var refreshMappedProfiles = [];
      for(let i=0; i < this.mappedProfiles.length; i++){
        var profileID = this.mappedProfiles[i].Id;
        $('.mapped-list-'+this.mappedProfiles[i].Id+' .listing li .tbl-badges').each(function(){
          var mappedId = $(this).data('profileid');
          
          refreshMappedProfiles.push({profileID: profileID, mappedId: mappedId});
        });

      }

      try {
        vm.$store.commit("loadingStatus", true);
        const response = await profileMappingService.bulkMappingProfile(
          refreshMappedProfiles
        );
        vm.toastMessage(response.message, "success");
        vm.getProfiles();
        this.unsavedChanges = false;
        vm.$store.commit("loadingStatus", false);
      } catch (error) {
        const message = vm.errorMessage(error);
        vm.toastMessage(message, "error");
        vm.$store.commit("loadingStatus", false);
      }

    },
    async mapUnMapProfile(event, bdTeamId) {
      let vm = this;

      if (event.removed && event.removed.element.Id) {
        try {
          vm.$store.commit("loadingStatus", true);
          await profileMappingService.unMappingProfile(
            event.removed.element.Id
          );
          vm.getProfiles();
          vm.$store.commit("loadingStatus", false);
        } catch (error) {
          const message = vm.errorMessage(error);
          vm.toastMessage(message, "error");
          vm.$store.commit("loadingStatus", false);
        }
      }

      if (
        event.added &&
        (event.added.element.Id ||
          (event.added.element.ProfileId && event.added.element.BdTeamId))
      ) {
        let profileId = event.added.element.BdTeamId
          ? event.added.element.ProfileId
          : event.added.element.Id;

        setTimeout(async () => {
          try {
            vm.$store.commit("loadingStatus", true);
            await profileMappingService.mappingProfile(bdTeamId, profileId);
            vm.searchUnmappedProfilequery = null;
            vm.getProfiles();
            vm.$store.commit("loadingStatus", false);
          } catch (error) {
            const message = vm.errorMessage(error);
            vm.toastMessage(message, "error");
            vm.$store.commit("loadingStatus", false);
          }
        }, 50);
      }

      let dragableColumn = document.getElementsByClassName("dragable-column");
      for (let index = 0; index < dragableColumn.length; index++) {
        const element = dragableColumn[index];
        if (element.classList.contains("active")) {
          element.classList.remove("active");
        }
      }
    },
    async unMappingProfile(element) {

      //let vm = this;
      if(element.ProfileId == undefined){
        $('.mapped-profile-list .tbl-badges[data-profileid="'+element.Id+'"]').parent('li').remove();
      }else{
        $('.mapped-profile-list .tbl-badges[data-profileid="'+element.ProfileId+'"]').parent('li').remove();
      }
      this.unsavedChanges = true;
      this.updateCount();
      /* if(element.ProfileId == undefined){
      }else{

        if (element.Id) {
          try {
            vm.$store.commit("loadingStatus", true);
            await profileMappingService.unMappingProfile(element.Id);
            vm.getProfiles();
            vm.$store.commit("loadingStatus", false);
          } catch (error) {
            const message = vm.errorMessage(error);
            vm.toastMessage(message, "error");
            vm.$store.commit("loadingStatus", false);
          }
        }
      } */
    },
    performMappedProfilesSearch: function () {
      var oTable = $(".table").dataTable();
      oTable.fnFilter(this.searchMappedProfilequery);
    },
    async performUnmappedProfilesSearch() {
      let vm = this;

      /* get dragged profiles which are not saved yet */
      let unsavedProfiles = [];

      for(let i=0; i < this.mappedProfiles.length; i++){
        $('.mapped-list-'+this.mappedProfiles[i].Id+' .listing li .tbl-badges[data-is-dragged="true"]').each(function(){
          unsavedProfiles.push($(this).data('profileid'));
        });
      }

      try {
        vm.$store.commit("loadingStatus", true);
        const response = await profileMappingService.searchUnmappedProfiles(
          this.searchUnmappedProfilequery,
          unsavedProfiles
        );
        vm.unmappedProfiles = response.data;
        vm.$store.commit("loadingStatus", false);
      } catch (error) {
        const message = vm.errorMessage(error);
        vm.toastMessage(message, "error");
        vm.$store.commit("loadingStatus", false);
      }
    },
    checkMoveUnmapped() {
      let dragableColumn = document.getElementsByClassName("dragable-column");
      for (let index = 0; index < dragableColumn.length; index++) {
        const element = dragableColumn[index];
        element.classList.add("active");
      }
    },
    mappedDragStart() {
      let dragableColumn = document.getElementsByClassName("dragable-column");
      for (let index = 0; index < dragableColumn.length; index++) {
        const element = dragableColumn[index];
        element.classList.add("active");
      }
    },
    mappedDragEnd() {
      let dragableColumn = document.getElementsByClassName("dragable-column");
      for (let index = 0; index < dragableColumn.length; index++) {
        const element = dragableColumn[index];
        element.classList.remove("active");
      }
      this.unsavedChanges = true;
      this.updateCount();
    },
    updateCount(){
      for(let i=0; i < this.mappedProfiles.length; i++){
        $("tr#"+this.mappedProfiles[i].Id+" td:nth-child(3)").html($('.mapped-list-'+this.mappedProfiles[i].Id+' li').length);
      }
    }
  },
  watch: {
    userPermissions: {
      handler: function () {
        this.getProfiles();
      },
      deep: true,
    },
  },
  beforeRouteLeave (to, from , next) {
    if(this.unsavedChanges == true){
      const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
      if (answer) {
        next()
      } else {
        next(false)
      }
    }else{
      next()
    }
  }
  
};

$(window).on("beforeunload", function() {
  if($('input[name="unsavedChanges"]').val() == 'true'){
    return "Are you sure you want leave?";
  }
});

</script>
